import React, { ComponentType, lazy, LazyExoticComponent } from 'react';
import { getAllPermissionRolesForUserLevel, PERMISSIONROLECONSTANTS } from '../redux/utils/utils.constants';
import { equipmentGroupPermissions } from '../views/Main/EquipmentGroups/EquipmentGroup.permissions';
import { serviceCallTechnicianPermissions } from '../views/Main/ServiceCallTechnician/serviceCallTechnician';

export const getDashboardPath = '/dashboard';
export const getNOCPath = `${getDashboardPath}/noc`;
export const getDashboardOverviewPath = `${getDashboardPath}/overview`;
export const getInventoryDashboardOverviewPath = `${getDashboardPath}/inventory`;
export const getDashboardMapPath = `${getDashboardPath}/map`;
export const getJotFormOrderPage = `${getDashboardPath}/jot-form-order`;
export const getOtherPath = '/other';
export const getSupportPath = '/support';
export const getServiceCallPath = '/service-call';
export const getOrdersListPath = `${getDashboardPath}/orders`;
export const getOrdersDetailsPath = `${getDashboardPath}/orders/:id`;
export const getAlertLogsListPath = `${getDashboardPath}/alert-logs`;
export const getSalesReportsPath = `${getDashboardPath}/sales-reports`;

export const getOrganizationsPath = '/organizations';
export const getOrganizationListPath = `${getOrganizationsPath}/organizations`;
export const getOrganizationDetailsPath = `${getOrganizationListPath}/:id`;
export const getLocationListPath = `${getOrganizationsPath}/locations`;
export const getLocationsBulkDetailsPath = `${getOrganizationsPath}/locations/bulk`;
export const getLocationDetailsPath = `${getLocationListPath}/:id`;
export const getUserListPath = `${getOrganizationsPath}/users`;
export const getUserDetailsPath = `${getUserListPath}/:id`;
export const getWarehousesListPath = `${getOrganizationsPath}/warehouses`;
export const getWarehousesDetailsPath = `${getOrganizationsPath}/warehouses/:id`;

export const getServicesPath = '/services';
export const getServiceListPath = `${getServicesPath}/services`;
export const getServiceDetailsPath = `${getServiceListPath}/:id`;
export const getServiceTypeListPath = `${getServicesPath}/service-types`;
export const getServiceTypeDetailsPath = `${getServiceTypeListPath}/:id`;
export const getRevIoFieldIdsListPath = `${getServicesPath}/revio-field-id`;

export const getTelcoPath = '/telco';
export const getPBXListPath = `${getTelcoPath}/pbxs`;
export const getPBXDetailsPath = `${getPBXListPath}/:id`;
export const getPbxExtensionsListPath = `${getTelcoPath}/pbx-extensions`;
export const getPbxExtensionDetailsPath = `${getPbxExtensionsListPath}/:id`;
export const getDIDListPath = `${getTelcoPath}/dids`;
export const getDIDDetailsPath = `${getDIDListPath}/:id`;
export const getDIDBulkDetailsPath = `${getDIDListPath}/bulk`;
export const getCdrsListPath = `${getTelcoPath}/cdrs`;
export const getDialerLogsListPath = `${getTelcoPath}/dialer-logs`;
export const getPortingDetailsPath = `${getTelcoPath}/porting/:id`;
export const getPortingListPath = `${getTelcoPath}/porting`;
export const getContactIDMessagesPath = `${getTelcoPath}/contact-id-messages`;

export const getConnectivityPath = '/connectivity';
export const getConnectivityListPath = `${getConnectivityPath}/sims`;
export const getSimsListPath = `${getConnectivityPath}/tc-sims`;
export const getConnectivityRoutersListPath = `${getConnectivityPath}/routers`;
export const getRoutersBulkDetailsPath = `${getConnectivityPath}/routers/bulk`;
export const getSimsDetailsPath = `${getSimsListPath}/:id`;
export const getSimsBulkDetailsPath = `${getSimsListPath}/bulk`;
export const getDataPlanTypeListPath = `${getConnectivityPath}/data-plan-types`;
export const getDataPlanTypeDetailsPath = `${getDataPlanTypeListPath}/:id`;
export const getDataPlanListPath = `${getConnectivityPath}/data-plans`;
export const getDataPlanDetailsPath = `${getDataPlanListPath}/:id`;

export const getEquipmentsPath = '/equipments';
export const getEquipmentGroupListPath = `${getEquipmentsPath}/groups`;
export const getEquipmentGroupDetailsPath = `${getEquipmentGroupListPath}/:id`;
export const getRouterListPath = `${getEquipmentsPath}/routers`;
export const getSyncRoutersListPath = `${getEquipmentsPath}/sync-routers`;
export const getRouterDetailsPath = `${getRouterListPath}/:id`;
export const getBatteryListPath = `${getEquipmentsPath}/batteries`;
export const getBatteriesBulkDetailsPath = `${getEquipmentsPath}/batteries/bulk`;
export const getBatteryDetailsPath = `${getBatteryListPath}/:id`;
export const getATAListPath = `${getEquipmentsPath}/atas`;
export const getATAGrandstreamPath = `${getATAListPath}-grandstream`;
export const getATAACSPath = `${getATAListPath}-acs`;
export const getATAGrandstreamDetailsPath = `${getATAGrandstreamPath}/:id`;
export const getATAACSDetailsPath = `${getATAACSPath}/:id`;
export const getATABulkDetailsPath = `${getATAListPath}/bulk`;
export const getATAOrgTemplatesListPath = `${getEquipmentsPath}/ata-org-templates`;
export const getATAOrgTemplateDetailsPath = `${getATAOrgTemplatesListPath}/:id`;
export const getATABaseTemplatesListPath = `${getEquipmentsPath}/ata-base-templates`;
export const getATABaseTemplateDetailsPath = `${getATABaseTemplatesListPath}/:id`;
export const getATATypeListPath = `${getEquipmentsPath}/ata-types`;
export const getATATypeDetailsPath = `${getATATypeListPath}/:id`;
export const getXMLRequestLogsPath = `${getEquipmentsPath}/xml-request-logs`;
export const getGdmsCallbackLogsPath = `${getEquipmentsPath}/atas/statusLogs`;
export const getActionLogsPath = `${getEquipmentsPath}/ata-logs`;
export const getDialersListPath = `${getEquipmentsPath}/dialers`;
export const getDialerDetailsPath = `${getDialersListPath}/:id`;
export const getDialersBulkDetailsPath = `${getEquipmentsPath}/dialers/bulk`;
export const getEnclosuresListPath = `${getEquipmentsPath}/enclosures`;
export const getEnclosuresDetailsPath = `${getEquipmentsPath}/enclosures/:id`;
export const getEnclosuresBulkDetailsPath = `${getEquipmentsPath}/enclosures/bulk`;
export const getPowerCyclesListPath = `${getEquipmentsPath}/power-cycles`;
export const getPowerCyclesDetailsPath = `${getEquipmentsPath}/power-cycles/:id`;
export const getPowerCyclesBulkDetailsPath = `${getEquipmentsPath}/power-cycles/bulk`;
export const getEnclosureTypesListPath = `${getEquipmentsPath}/enclosure-types`;
export const getEnclosureTypeDetailsPath = `${getEnclosureTypesListPath}/:id`;
export const getInventoryListPath = `${getEquipmentsPath}/inventory`;

export const getLegalPage = '/legal';
export const getServiceLevelAgreementPage = '/sla';
export const getSupportServicesPage = '/ss';
export const getPrivacyPolicyPage = '/pp';
export const getAcceptableUsePolicyPage = '/aup';
export const get911TermsAndConditionsPage = '/911';
export const getPricingPage = '/pricing';
export const getMsaPage = '/msa';

export const getDocsPath = '/docs';
export const getPotsPortalPath = 'https://docs.potsportal.com';
export const getCustomerApiDocsPath = `${getDocsPath}/potsportal-customer-api`;
export const getReleaseNotesPath = '/release-notes';
export const getSingleReleaseNotesPath = `${getReleaseNotesPath}/:id`;

export const getCountriesListPath = `${getOtherPath}/countries`;
export const getCountriesDetailsPath = `${getOtherPath}/countries/:id`;
export const getAuditLogsListPath = `${getOtherPath}/audit-logs`;
export const getAsyncTasksListPath = `${getOtherPath}/async-tasks`;
export const getErrorLogsListPath = `${getOtherPath}/error-logs`;
export const getIssueTrackerListPath = `${getOtherPath}/issue-tracker-items`;
export const getIssueTrackerDetailsPath = `${getIssueTrackerListPath}/:id`;
export const getRevioVerificationReportsPath = `${getOtherPath}/revio-verification-reports`;
export const getAccessAndVisibilityPath = `${getOtherPath}/access-visibility`;

export const getTicketListPath = `${getSupportPath}/tickets`;
export const getTicketDetailsPath = `${getTicketListPath}/:id`;
export const getSecureALARMManager = '/secureAlarmManager';
export const getSecureALARMManagerBuckets = `${getSecureALARMManager}/buckets`;

export const getServiceCallRequestsListPath = `${getServiceCallPath}/requests`;
export const getServiceCallRequestDetailsPath = `${getServiceCallRequestsListPath}/:id`;
export const getServiceCallProvidersListPath = `${getServiceCallPath}/providers`;
export const getServiceCallProviderDetailsPath = `${getServiceCallProvidersListPath}/:id`;
export const getServiceCallTechniciansListPath = `${getServiceCallPath}/technicians`;
export const getServiceCallTechnicianDetailsPath = `${getServiceCallTechniciansListPath}/:id`;
export const getServiceCallSiteSurveysListPath = `${getServiceCallPath}/site-surveys`;
export const getServiceCallSiteSurveyDetailsPath = `${getServiceCallSiteSurveysListPath}/:id`;

export const getSecureALARMManagerBucketDetails = `${getSecureALARMManagerBuckets}/:id`;
export const getSecureALARMManagerDids = `${getSecureALARMManager}/dids`;
export const getSecureALARMManagerDidsBulk = `${getSecureALARMManager}/dids/bulk`;

const Dashboard = lazy(() => import('../views/Main/Dashboard/Dashboard'));
const NOC = lazy(() => import('../views/Main/NOC/NOC'));
const InventoryDashboard = lazy(() => import('../views/Main/InventoryDashboard/InventoryDashboard'));
const Map = lazy(() => import('../views/Main/Map/Map'));
const Order = lazy(() => import('../views/Main/Order/Order'));
const OrdersList = lazy(() => import('../views/Main/Orders/OrdersList/OrdersList'));
const OrderDetails = lazy(() => import('../views/Main/Orders/OrderDetails/OrderDetails'));
const AlertLogsList = lazy(() => import('../views/Main/AlertLogs/AlertLogs/AlertLogs'));
const SalesReports = lazy(() => import('../views/Main/SalesReports/SalesReports'));

const OrganizationsList = lazy(() => import('../views/Main/Organizations/OrganizationsList/OrganizationsList'));
const OrganizationDetails = lazy(() => import('../views/Main/Organizations/OrganizationDetails/OrganizationDetails'));
const LocationsList = lazy(() => import('../views/Main/Locations/LocationsList/LocationsList'));
const LocationDetails = lazy(() => import('../views/Main/Locations/LocationDetails/LocationDetails'));
const LocationsBulk = lazy(() => import('../views/Main/Locations/LocationsBulk/LocationsBulk'));
const WarehousesList = lazy(() => import('../views/Main/Warehouses/WarehousesList/WarehousesList'));
const WarehouseDetails = lazy(() => import('../views/Main/Warehouses/WarehouseDetails/WarehouseDetails'));
const UsersList = lazy(() => import('../views/Main/Users/UsersList/UsersList'));
const UserDetails = lazy(() => import('../views/Main/Users/UserDetails/UserDetails'));

const TicketDetails = lazy(() => import('../views/Main/Tickets/TicketDetails/TicketDetails'));
const TicketsList = lazy(() => import('../views/Main/Tickets/TicketsList/TicketsList'));

const ServicesList = lazy(() => import('../views/Main/Services/ServicesList/ServicesList'));
const ServiceDetails = lazy(() => import('../views/Main/Services/ServiceDetails/ServiceDetails'));
const ServiceTypesList = lazy(() => import('../views/Main/ServiceTypes/ServiceTypesList/ServiceTypesList'));
const ServiceTypeDetails = lazy(() => import('../views/Main/ServiceTypes/ServiceTypeDetails/ServiceTypeDetails'));
// const RevIoFieldIDsList = lazy(() => import('../views/Main/RevIoFieldIds/RevIoFieldIdsList/RevIoFieldIdsList'));
const RevIoFieldIDDetails = lazy(() => import('../views/Main/RevIoFieldIds/RevIoFieldIdDetails/RevIoFieldIdDetails'));

const PBXsList = lazy(() => import('../views/Main/PBXs/PBXsList/PBXsList'));
const PBXDetails = lazy(() => import('../views/Main/PBXs/PBXDetails/PBXDetails'));
const PBXExtensionsList = lazy(() => import('../views/Main/PBXExtensions/PBXExtensionsList/PBXExtensionsList'));
const PBXExtensionDetails = lazy(() => import('../views/Main/PBXExtensions/PBXExtensionDetails/PBXExtensionDetails'));
const SyncRouters = lazy(() => import('../views/Main/SyncRouters/SyncRouters'));
const DIDDetails = lazy(() => import('../views/Main/DIDs/DIDDetails/DIDDetails'));
const DIDsBulk = lazy(() => import('../views/Main/DIDs/DIDsBulk/DIDsBulk'));
const DIDsList = lazy(() => import('../views/Main/DIDs/DIDsList/DIDsList'));
const CDRsList = lazy(() => import('../views/Main/CDRs/CDRsList/CDRsList'));
const DialerLogsList = lazy(() => import('../views/Main/DialerLogs/DialerLogsList/DialerLogsList'));
const PortingList = lazy(() => import('../views/Main/Porting/PortingList/PortingList'));
const PortingDetails = lazy(() => import('../views/Main/Porting/PortingDetails/PortingDetails'));

const EquipmentGroupsList = lazy(() => import('../views/Main/EquipmentGroups/EquipmentGroupsList/EquipmentGroupsList'));
const EquipmentGroupDetails = lazy(
  () => import('../views/Main/EquipmentGroups/EquipmentGroupDetails/EquipmentGroupDetails')
);
const BatteriesList = lazy(() => import('../views/Main/Batteries/BatteriesList/BatteriesList'));
const BatteryDetails = lazy(() => import('../views/Main/Batteries/BatteryDetails/BatteryDetails'));
const BatteriesBulk = lazy(() => import('../views/Main/Batteries/BatteriesBulk/BatteriesBulk'));
const RoutersList = lazy(() => import('../views/Main/Routers/RoutersList/RoutersList'));
const RouterDetails = lazy(() => import('../views/Main/Routers/RouterDetails/RouterDetails'));
const RoutersBulk = lazy(() => import('../views/Main/Routers/RoutersBulk/RoutersBulk'));
const ATAsList = lazy(() => import('../views/Main/ATAs/ATAsList/ATAsList'));
const ATAGrandstreamDetails = lazy(() => import('../views/Main/ATAs/ATADetails/ATAGrandstreamDetails'));
const ATAACSDetails = lazy(() => import('../views/Main/ATAs/ATADetails/ATAACSDetails'));
const ATAsBulk = lazy(() => import('../views/Main/ATAs/ATAsBulk/ATAsBulk'));
const ATATypesList = lazy(() => import('../views/Main/ATATypes/ATATypesList/ATATypesList'));
const ATATypeDetails = lazy(() => import('../views/Main/ATATypes/ATATypeDetails/ATATypeDetails'));
const ATAOrgTemplatesList = lazy(() => import('../views/Main/ATAOrgTemplates/ATAOrgTemplatesList/ATAOrgTemplatesList'));
const ATAOrgTemplateDetails = lazy(
  () => import('../views/Main/ATAOrgTemplates/ATAOrgTemplateDetails/ATAOrgTemplateDetails')
);
const ATABaseTemplatesList = lazy(
  () => import('../views/Main/ATABaseTemplates/ATABaseTemplatesList/ATABaseTemplatesList')
);
const ATABaseTemplateDetails = lazy(
  () => import('../views/Main/ATABaseTemplates/ATABaseTemplateDetails/ATABaseTemplateDetails')
);
const DialersList = lazy(() => import('../views/Main/Dialers/DialersList/DialersList'));
const DialerDetails = lazy(() => import('../views/Main/Dialers/DialerDetails/DialerDetails'));
const DialersBulk = lazy(() => import('../views/Main/Dialers/DialersBulk/DialersBulk'));
const EnclosuresList = lazy(() => import('../views/Main/Enclosures/EnclosuresList/EnclosuresList'));
const EnclosureDetails = lazy(() => import('../views/Main/Enclosures/EnclosureDetails/EnclosureDetails'));
const EnclosuresBulk = lazy(() => import('../views/Main/Enclosures/EnclosuresBulk/EnclosuresBulk'));
const PowerCyclesList = lazy(() => import('../views/Main/PowerCycles/PowerCyclesList/PowerCyclesList'));
const PowerCycleDetails = lazy(() => import('../views/Main/PowerCycles/PowerCycleDetails/PowerCycleDetails'));
const PowerCyclesBulk = lazy(() => import('../views/Main/PowerCycles/PowerCyclesBulk/PowerCyclesBulk'));

const EnclosureTypesList = lazy(() => import('../views/Main/EnclosureTypes/EnclosureTypesList/EnclosureTypesList'));
const EnclosureTypeDetails = lazy(
  () => import('../views/Main/EnclosureTypes/EnclosureTypeDetails/EnclosureTypeDetails')
);
const InventoriesList = lazy(() => import('../views/Main/Inventories/InventoriesList/InventoriesList'));

const SIMsList = lazy(() => import('../views/Main/SIMs/SIMsList/SIMsList'));
const SIMDetails = lazy(() => import('../views/Main/SIMs/SIMDetails/SIMDetails'));
const SIMsBulk = lazy(() => import('../views/Main/SIMs/SIMsBulk/SIMsBulk'));
const ConnectivityRoutersList = lazy(
  () => import('../views/Main/ConnectivityRouters/ConnectivityRoutersList/ConnectivityRoutersList')
);
const DataPlanTypesList = lazy(() => import('../views/Main/DataPlanTypes/DataPlanTypesList/DataPlanTypesList'));
const DataPlanTypeDetails = lazy(() => import('../views/Main/DataPlanTypes/DataPlanTypeDetails/DataPlanTypeDetails'));
const DataPlansList = lazy(() => import('../views/Main/DataPlans/DataPlansList/DataPlansList'));
const DataPlanDetails = lazy(() => import('../views/Main/DataPlans/DataPlanDetails/DataPlanDetails'));

const Legal = lazy(() => import('../views/Main/Static/legal/Legal'));
const SLA = lazy(() => import('../views/Main/Static/serviceLevelAgreement/ServiceLevelAgreement'));
const SS = lazy(() => import('../views/Main/Static/supportServices/SupportServices'));
const PP = lazy(() => import('../views/Main/Static/privacyPolicy/PrivacyPolicy'));
const AUP = lazy(() => import('../views/Main/Static/acceptableUsePolicy/Aup'));
const Page911 = lazy(() => import('../views/Main/Static/911TermsAndConditions/911TermsAndConditions'));
const Pricing = lazy(() => import('../views/Main/Static/pricing/Pricing'));
const MSA = lazy(() => import('../views/Main/Static/masterServicesAgreement/MasterServicesAgreement'));

const APIDocumentation = lazy(() => import('../views/Main/APIDocumentation/APIDocumentation'));
const ReleaseNotes = lazy(() => import('../views/Main/ReleaseNotes/ReleaseNotes'));
const SingleReleaseNote = lazy(() => import('../views/Main/ReleaseNotes/SingleReleaseNote'));

const CountriesList = lazy(() => import('../views/Main/Countries/CountriesList/CountriesList'));
const CountryDetails = lazy(() => import('../views/Main/Countries/CountryDetails/CountryDetails'));
const AuditLogsList = lazy(() => import('../views/Main/AuditLogs/AuditLogsList/AuditLogsList'));
const AsyncTasksList = lazy(() => import('../views/Main/AsyncTasks/AsyncTasksList/AsyncTasksList'));
const ErrorLogsList = lazy(() => import('../views/Main/ErrorLogs/ErrorLogsList/ErrorLogsList'));
const IssuesTrackerList = lazy(() => import('../views/Main/IssuesTracker/IssuesTrackerList/IssuesTrackerList'));
const IssueTrackerDetails = lazy(() => import('../views/Main/IssuesTracker/IssueTrackerDetails/IssueTrackerDetails'));
const RevioReport = lazy(() => import('../views/Main/AdminTools/RevIoReport/RevIoReport'));
const AccessAndVisibility = lazy(() => import('../views/Main/AccessAndVisibility/AccessAndVisibility'));
const ContactIDMessagesList = lazy(
  () => import('../views/Main/ContactIDMessages/ContactIDMessagesList/ContactIDMessagesList')
);

const BucketsList = lazy(() => import('../views/Main/SecureAlarmManager/Buckets/list/BucketsList'));
const BucketDetails = lazy(() => import('../views/Main/SecureAlarmManager/Buckets/detail/BucketDetail'));
const SecureAlarmDidsList = lazy(() => import('../views/Main/SecureAlarmManager/Dids/list/DidsList'));
const SecureAlarmDidsBulk = lazy(() => import('../views/Main/SecureAlarmManager/Dids/bulk/DidsBulk'));

const ServiceCallTechniciansList = lazy(
  () => import('../views/Main/ServiceCallTechnician/ServiceCallTechniciansList/ServiceCallTechniciansList')
);
const ServiceCallTechnicianDetails = lazy(
  () => import('../views/Main/ServiceCallTechnician/ServiceCallTechnicianDetails/ServiceCallTechnicianDetails')
);

export type RouteType = {
  collapse?: boolean;
  path: string;
  exact: boolean;
  name: string;
  icon?: string;
  state?: string;
  component?: LazyExoticComponent<ComponentType<unknown>> | LazyExoticComponent<React.FC>;
  allowedPermissionRoles: string[];
  child?: RouteType[];
  hidden?: boolean;
  isExternal?: boolean;
  pathTo?: string;
  redirect?: boolean;
};

const ThemeRoutes: Array<RouteType> = [
  {
    collapse: true,
    path: `${getDashboardPath}`,
    exact: true,
    name: 'Dashboard',
    icon: 'fa fa-bars',
    state: 'dashboard',
    component: NOC,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    child: [
      {
        path: `${getNOCPath}`,
        exact: true,
        name: 'NOC',
        state: 'noc',
        icon: 'fa fa-bars',
        component: NOC,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getDashboardOverviewPath}`,
        exact: true,
        name: 'Overview',
        state: 'overview',
        icon: 'fa fa-bars',
        component: Dashboard,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getInventoryDashboardOverviewPath}`,
        exact: true,
        name: 'Inventory',
        state: 'inventory',
        icon: 'fa fa-bars',
        component: InventoryDashboard,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
        ],
      },
      {
        path: `${getDashboardMapPath}`,
        exact: true,
        name: 'Map',
        state: 'map',
        icon: 'fa fa-bars',
        component: Map,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getJotFormOrderPage}`,
        exact: true,
        name: 'Order - jotform',
        state: 'order-jotform',
        icon: 'fa fa-bars',
        component: Order,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 'user', 't3_admin']),
          ...getAllPermissionRolesForUserLevel('reseller'),
        ],
      },
      {
        path: `${getOrdersListPath}`,
        exact: true,
        name: 'Orders',
        state: 'orders-list',
        icon: 'fa fa-bars',
        component: OrdersList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getAlertLogsListPath}`,
        exact: true,
        name: 'Alerts',
        state: 'alerts-list',
        icon: 'fa fa-bars',
        component: AlertLogsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getSalesReportsPath}`,
        exact: true,
        name: 'Sales Reports',
        state: 'sales-reports-list',
        icon: 'fa fa-bars',
        component: SalesReports,
        allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin, ...getAllPermissionRolesForUserLevel('telcloud')],
      },
    ],
  },
  // ORGANIZATIONS
  {
    collapse: true,
    path: `${getOrganizationsPath}`,
    exact: true,
    name: 'Organizations',
    icon: 'fab fa-connectdevelop',
    state: 'organizations',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    child: [
      {
        path: `${getOrganizationListPath}`,
        exact: true,
        name: 'Organizations',
        icon: 'fab fa-connectdevelop',
        state: 'organizations-list',
        component: OrganizationsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getLocationListPath}`,
        exact: true,
        name: 'Locations',
        state: 'locations-list',
        icon: 'fab fa-connectdevelop',
        component: LocationsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getWarehousesListPath}`,
        exact: true,
        name: 'Warehouses',
        state: 'warehouses-list',
        icon: 'fab fa-connectdevelop',
        component: WarehousesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
          ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
        ],
      },
      {
        path: `${getUserListPath}`,
        exact: true,
        name: 'Users',
        state: 'users-list',
        icon: 'fab fa-connectdevelop',
        component: UsersList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
          ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
          ...getAllPermissionRolesForUserLevel('customer', ['owner', 'admin']),
        ],
      },
    ],
  },
  // SUPPORT
  {
    collapse: true,
    path: `${getSupportPath}`,
    exact: true,
    name: 'Support',
    icon: 'fa fa-headset',
    state: 'support',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
    ],
    child: [
      {
        path: `${getTicketListPath}`,
        exact: true,
        name: 'Tickets',
        state: 'tickets-list',
        icon: 'fa fa-headset',
        component: TicketsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
        ],
      },
    ],
  },
  // SERVICES
  {
    path: `${getServicesPath}`,
    exact: true,
    name: 'Services',
    state: 'services',
    icon: 'fas fa-tools',
    component: ServicesList,
    collapse: true,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    child: [
      {
        path: `${getServiceListPath}`,
        exact: true,
        name: 'Services Info',
        icon: 'fas fa-tools',
        state: 'services-list',
        component: ServicesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getServiceTypeListPath}`,
        exact: true,
        name: 'Service Types',
        state: 'services-types-list',
        icon: 'fas fa-tools',
        component: ServiceTypesList,
        allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
      },
      // {
      //   path: `${getRevIoFieldIdsListPath}`,
      //   exact: true,
      //   name: 'Rev.io Field IDs',
      //   icon: 'fas fa-tools',
      //   component: RevIoFieldIDsList,
      //   allowedPermissionRoles: [...RevIoFieldIdPermissions.READ],
      // },
    ],
  },
  // TELCO
  {
    collapse: true,
    path: `${getTelcoPath}`,
    exact: true,
    name: 'Telco',
    icon: 'fa fa-cloud',
    state: 'telco',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    child: [
      {
        path: `${getPBXListPath}`,
        exact: true,
        name: 'PBXs',
        state: 'pbxs-list',
        icon: 'fa fa-cloud',
        component: PBXsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      // {
      //   path: `${getPbxExtensionsListPath}`,
      //   exact: true,
      //   name: 'PBX Extensions',
      //   icon: 'fa fa-cloud',
      //   component: PBXExtensionsList,
      //   allowedPermissionRoles: [
      //     PERMISSIONROLECONSTANTS.superadmin,
      //     ...getAllPermissionRolesForUserLevel('telcloud'),
      //     ...getAllPermissionRolesForUserLevel('reseller'),
      //     ...getAllPermissionRolesForUserLevel('customer'),
      //   ],
      // },
      {
        path: `${getDIDListPath}`,
        exact: true,
        name: 'DIDs',
        state: 'dids-list',
        icon: 'fa fa-cloud',
        component: DIDsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getCdrsListPath}`,
        exact: true,
        name: 'CDRs',
        state: 'cdrs-list',
        icon: 'fa fa-cloud',
        component: CDRsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getDialerLogsListPath}`,
        exact: true,
        name: 'Dialer Logs',
        state: 'dialer-logs-list',
        icon: 'fa fa-briefcase',
        component: DialerLogsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getContactIDMessagesPath}`,
        exact: true,
        name: 'Contact ID Messages',
        state: 'contact-id-messages-list',
        icon: 'fa fa-sitemap',
        component: ContactIDMessagesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getPortingListPath}`,
        exact: true,
        name: 'Porting',
        state: 'porting-list',
        icon: 'fa fa-briefcase',
        component: PortingList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
    ],
  },
  // EQUIPMENT
  {
    collapse: true,
    path: `${getEquipmentsPath}`,
    exact: true,
    name: 'Equipment',
    icon: 'fa fa-briefcase',
    state: 'equipment',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    child: [
      {
        path: `${getEquipmentGroupListPath}`,
        exact: true,
        name: 'Groups',
        state: 'equipment-groups-list',
        icon: 'fa fa-briefcase',
        component: EquipmentGroupsList,
        allowedPermissionRoles: equipmentGroupPermissions.READ,
      },
      {
        path: `${getBatteryListPath}`,
        exact: true,
        name: 'Batteries',
        state: 'batteries-list',
        icon: 'fa fa-briefcase',
        component: BatteriesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getRouterListPath}`,
        exact: true,
        name: 'Routers',
        state: 'routers-list',
        icon: 'fa fa-briefcase',
        component: RoutersList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getATAListPath}`,
        exact: true,
        name: 'ATAs',
        state: 'atas-list',
        icon: 'fa fa-briefcase',
        component: ATAsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getATATypeListPath}`,
        exact: true,
        name: 'ATA Types',
        state: 'ata-types-list',
        icon: 'fa fa-briefcase',
        component: ATATypesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getATAOrgTemplatesListPath}`,
        exact: true,
        name: 'ATA Org Templates',
        state: 'ata-org-templates-list',
        icon: 'fa fa-briefcase',
        component: ATAOrgTemplatesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getATABaseTemplatesListPath}`,
        exact: true,
        name: 'ATA Base Templates',
        state: 'ata-base-templates-list',
        icon: 'fa fa-briefcase',
        component: ATABaseTemplatesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getDialersListPath}`,
        exact: true,
        name: 'Dialers',
        state: 'dialers-list',
        icon: 'fa fa-briefcase',
        component: DialersList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getEnclosuresListPath}`,
        exact: true,
        name: 'Enclosures',
        state: 'enclosures-list',
        icon: 'fa fa-briefcase',
        component: EnclosuresList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getEnclosureTypesListPath}`,
        exact: true,
        name: 'Enclosure Types',
        state: 'enclosure-types-list',
        icon: 'fa fa-briefcase',
        component: EnclosureTypesList,
        allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
      },
      {
        path: `${getPowerCyclesListPath}`,
        exact: true,
        name: 'PowerCycle',
        state: 'powercycle-list',
        icon: 'fa fa-briefcase',
        component: PowerCyclesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getInventoryListPath}`,
        exact: true,
        name: 'Inventory',
        state: 'inventory-list',
        icon: 'fa fa-briefcase',
        component: InventoriesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
        ],
      },
    ],
  },
  // CONNECTIVITY
  {
    collapse: true,
    path: `${getConnectivityPath}`,
    exact: true,
    name: 'Connectivity',
    icon: 'fa fa-globe',
    state: 'connectivity',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    child: [
      {
        path: `${getSimsListPath}`,
        exact: true,
        name: 'SIMs',
        state: 'sims-list',
        icon: 'fa fa-globe',
        component: SIMsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getConnectivityRoutersListPath}`,
        exact: true,
        name: 'Router Data',
        icon: 'fa fa-globe',
        state: 'routers-data-list',
        component: ConnectivityRoutersList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getDataPlanListPath}`,
        exact: true,
        name: 'Data Plans',
        state: 'data-plans-list',
        icon: 'fa fa-briefcase',
        component: DataPlansList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getDataPlanTypeListPath}`,
        exact: true,
        name: 'Data Plan Types',
        state: 'data-plan-types-list',
        icon: 'fa fa-briefcase',
        component: DataPlanTypesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
    ],
  },
  // LEGAL
  {
    collapse: true,
    path: `${getLegalPage}`,
    exact: true,
    name: 'Legal',
    icon: 'fa fa-gavel',
    state: 'legal',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
    ],
    child: [
      {
        path: `${getMsaPage}`,
        exact: true,
        name: 'Master Services Agreement',
        state: 'master-services-agreement',
        icon: 'fa fa-gavel',
        component: MSA,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
        ],
      },
      {
        path: `${get911TermsAndConditionsPage}`,
        exact: true,
        name: '911 Terms & Conditions',
        state: '911-terms-&-conditions',
        icon: 'fa fa-gavel',
        component: Page911,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
        ],
      },
      {
        path: `${getSupportServicesPage}`,
        exact: true,
        name: 'Support Service',
        state: 'support-service',
        icon: 'fa fa-gavel',
        component: SS,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
        ],
      },
      {
        path: `${getAcceptableUsePolicyPage}`,
        exact: true,
        name: 'Acceptable Use Policy',
        state: 'acceptable-use-policy',
        icon: 'fa fa-gavel',
        component: AUP,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
        ],
      },
      {
        path: `${getPrivacyPolicyPage}`,
        exact: true,
        name: 'Privacy Policy',
        state: 'privacy-policy',
        icon: 'fa fa-gavel',
        component: PP,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
        ],
      },
    ],
  },
  // DOCs
  {
    collapse: true,
    path: `${getDocsPath}`,
    exact: true,
    name: 'Docs',
    icon: 'fa-sharp fa-solid fa-circle-info',
    state: 'docs',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    child: [
      {
        path: `${getPotsPortalPath}`,
        exact: true,
        isExternal: true,
        name: 'Need Help?',
        state: 'need-help',
        icon: 'fa-sharp fa-solid fa-circle-info',
        component: APIDocumentation,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getCustomerApiDocsPath}`,
        exact: true,
        name: 'API Documentation',
        state: 'api-documentation',
        icon: 'fa-sharp fa-solid fa-circle-info',
        component: APIDocumentation,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          ...getAllPermissionRolesForUserLevel('reseller'),
          ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
      {
        path: `${getReleaseNotesPath}`,
        exact: true,
        name: 'Release Notes',
        state: 'release-notes',
        icon: 'fa-sharp fa-solid fa-circle-info',
        component: ReleaseNotes,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud'),
          // ...getAllPermissionRolesForUserLevel('reseller'),
          // ...getAllPermissionRolesForUserLevel('customer'),
        ],
      },
    ],
  },
  // SECURE ALARM MANAGER
  {
    collapse: true,
    path: `${getSecureALARMManager}`,
    exact: true,
    name: 'Secure Alarm',
    state: 'secure-alarm',
    icon: 'fa-solid fa-business-time',
    allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
    child: [
      {
        path: `${getSecureALARMManagerBuckets}`,
        exact: true,
        name: 'Buckets',
        state: 'buckets-list',
        component: BucketsList,
        allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
      },
      {
        path: `${getSecureALARMManagerDids}`,
        exact: true,
        name: 'DIDs',
        state: 'secure-alarm-dids-list',
        component: SecureAlarmDidsList,
        allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
      },
    ],
  },
  // OTHER
  {
    collapse: true,
    path: `${getOtherPath}`,
    exact: true,
    name: 'Other',
    icon: 'fa fa-sitemap',
    state: 'other',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
      // ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
    child: [
      {
        path: `${getCountriesListPath}`,
        exact: true,
        name: 'Countries',
        icon: 'fa fa-sitemap',
        state: 'countries-list',
        component: CountriesList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getAuditLogsListPath}`,
        exact: true,
        name: 'Audit Logs',
        state: 'audit-logs-list',
        icon: 'fa fa-sitemap',
        component: AuditLogsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getAsyncTasksListPath}`,
        exact: true,
        name: 'Async Tasks',
        state: 'async-tasks-list',
        icon: 'fa fa-sitemap',
        component: AsyncTasksList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getErrorLogsListPath}`,
        exact: true,
        name: 'Error Logs',
        state: 'error-logs-list',
        icon: 'fa fa-sitemap',
        component: ErrorLogsList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getIssueTrackerListPath}`,
        exact: true,
        name: 'Issues Tracker',
        icon: 'fa fa-sitemap',
        state: 'issues-tracker-list',
        component: IssuesTrackerList,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getRevioVerificationReportsPath}`,
        exact: true,
        name: 'Rev.io Reports',
        icon: 'fa fa-sitemap',
        state: 'rev-io-reports-list',
        component: RevioReport,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ],
      },
      {
        path: `${getAccessAndVisibilityPath}`,
        exact: true,
        name: 'Access/Visibility',
        state: 'access-and-visibility',
        icon: 'fa fa-sitemap',
        component: AccessAndVisibility,
        allowedPermissionRoles: [
          PERMISSIONROLECONSTANTS.superadmin,
          ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
          // ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
        ],
      },
    ],
  },
  // OTHER
  {
    collapse: true,
    path: `${getServiceCallPath}`,
    exact: true,
    name: 'Service Calls',
    icon: 'fa-solid fa-server',
    state: 'service-call',
    allowedPermissionRoles: serviceCallTechnicianPermissions.READ,
    child: [
      {
        path: `${getServiceCallTechniciansListPath}`,
        exact: true,
        name: 'Service Technicians',
        icon: 'fa fa-sitemap',
        state: 'service-call-technicians-list',
        component: ServiceCallTechniciansList,
        allowedPermissionRoles: serviceCallTechnicianPermissions.READ,
      },
    ],
  },
  {
    path: `${getSingleReleaseNotesPath}`,
    exact: true,
    name: 'Release Note Details',
    component: SingleReleaseNote,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      // ...getAllPermissionRolesForUserLevel('reseller'),
      // ...getAllPermissionRolesForUserLevel('customer'),
    ],
  },
  // DASHBOARD
  {
    path: `${getOrdersDetailsPath}`,
    exact: true,
    name: 'Order Details',
    component: OrderDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  // ORGANIZATIONS
  {
    path: `${getOrganizationDetailsPath}`,
    exact: true,
    name: 'Organization Details',
    component: OrganizationDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getLocationsBulkDetailsPath}`,
    exact: true,
    name: 'Locations Bulk',
    component: LocationsBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getLocationDetailsPath}`,
    exact: true,
    name: 'Location Details',
    component: LocationDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getWarehousesDetailsPath}`,
    exact: true,
    name: 'Warehouse Details',
    component: WarehouseDetails,
    hidden: true,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
  },
  {
    path: `${getUserDetailsPath}`,
    exact: true,
    name: 'User Details',
    component: UserDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  // SUPPORT
  {
    path: `${getTicketDetailsPath}`,
    exact: true,
    name: 'Ticket Details',
    component: TicketDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
    ],
    hidden: true,
  },
  // SERVICES
  {
    path: `${getServiceDetailsPath}`,
    exact: true,
    name: 'Service Details',
    component: ServiceDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getServiceTypeDetailsPath}`,
    exact: true,
    name: 'Service Type Details',
    component: ServiceTypeDetails,
    allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
    hidden: true,
  },
  // {
  //   path: `${getRevIoFieldIdDetailsPath}`,
  //   exact: true,
  //   name: 'Rev.io Field ID Details',
  //   component: RevIoFieldIDDetails,
  //   allowedPermissionRoles: [...RevIoFieldIdPermissions.READ],
  //   hidden: true,
  // },
  //  TELCO
  {
    path: `${getPBXDetailsPath}`,
    exact: true,
    name: 'PBX Details',
    component: PBXDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  // {
  //   path: `${getPbxExtensionDetailsPath}`,
  //   exact: true,
  //   name: 'PBX Extension Details',
  //   component: PBXExtensionDetails,
  //   allowedPermissionRoles: [
  //     PERMISSIONROLECONSTANTS.superadmin,
  //     ...getAllPermissionRolesForUserLevel('telcloud'),
  //     ...getAllPermissionRolesForUserLevel('reseller'),
  //     ...getAllPermissionRolesForUserLevel('customer'),
  //   ],
  //   hidden: true,
  // },
  {
    path: `${getDIDBulkDetailsPath}`,
    exact: true,
    name: 'DIDs Bulk',
    component: DIDsBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 'user']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin', 'user']),
    ],
    hidden: true,
  },
  {
    path: `${getDIDDetailsPath}`,
    exact: true,
    name: 'DID Details',
    component: DIDDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getPortingDetailsPath}`,
    exact: true,
    name: 'Port Request Details',
    component: PortingDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  // EQUIPMENT
  {
    path: `${getEquipmentGroupDetailsPath}`,
    exact: true,
    name: 'Equipment Group Details',
    component: EquipmentGroupDetails,
    allowedPermissionRoles: equipmentGroupPermissions.READ,
    hidden: true,
  },
  {
    path: `${getBatteriesBulkDetailsPath}`,
    exact: true,
    name: 'Batteries Bulk',
    component: BatteriesBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin', 'user']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
    hidden: true,
  },
  {
    path: `${getBatteryDetailsPath}`,
    exact: true,
    name: 'Battery Details',
    component: BatteryDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getRoutersBulkDetailsPath}`,
    exact: true,
    name: 'Routers Bulk',
    component: RoutersBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
    hidden: true,
  },
  {
    path: `${getSyncRoutersListPath}`,
    exact: true,
    name: 'Sync Routers',
    component: SyncRouters,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
    hidden: true,
  },
  {
    path: `${getRouterDetailsPath}`,
    exact: true,
    name: 'Router Details',
    component: RouterDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getATABulkDetailsPath}`,
    exact: true,
    name: 'ATAs Bulk',
    component: ATAsBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
    ],
    hidden: true,
  },
  {
    path: `${getATAGrandstreamDetailsPath}`,
    exact: true,
    name: 'ATA Grandstream Details',
    component: ATAGrandstreamDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getATAACSDetailsPath}`,
    exact: true,
    name: 'ATA ACS Details',
    component: ATAACSDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getATATypeDetailsPath}`,
    exact: true,
    name: 'ATA Type Details',
    component: ATATypeDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
    ],
    hidden: true,
  },
  {
    path: `${getATAOrgTemplateDetailsPath}`,
    exact: true,
    name: 'ATA Org Template Details',
    component: ATAOrgTemplateDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
    ],
    hidden: true,
  },
  {
    path: `${getATABaseTemplateDetailsPath}`,
    exact: true,
    name: 'ATA Base Template Details',
    component: ATABaseTemplateDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
    ],
    hidden: true,
  },
  {
    path: `${getDialersBulkDetailsPath}`,
    exact: true,
    name: 'Dialers Bulk',
    component: DialersBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 'user', 't3_admin']),
      ...getAllPermissionRolesForUserLevel('telcloud', ['owner', 'admin']),
    ],
    hidden: true,
  },
  {
    path: `${getDialerDetailsPath}`,
    exact: true,
    name: 'Dialer Details',
    component: DialerDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getEnclosuresBulkDetailsPath}`,
    exact: true,
    name: 'Enclosures Bulk',
    component: EnclosuresBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 'user', 't3_admin']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
    hidden: true,
  },
  {
    path: `${getEnclosuresDetailsPath}`,
    exact: true,
    name: 'Enclosure Details',
    component: EnclosureDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getPowerCyclesBulkDetailsPath}`,
    exact: true,
    name: 'PowerCycle Bulk',
    component: PowerCyclesBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
    hidden: true,
  },
  {
    path: `${getPowerCyclesDetailsPath}`,
    exact: true,
    name: 'PowerCycle Details',
    component: PowerCycleDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getEnclosureTypeDetailsPath}`,
    exact: true,
    name: 'Enclosure Type Details',
    component: EnclosureTypeDetails,
    allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
    hidden: true,
  },
  // CONNECTIVITY
  {
    path: `${getSimsBulkDetailsPath}`,
    exact: true,
    name: 'SIMs Bulk',
    component: SIMsBulk,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 'user', 't3_admin']),
      ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
    ],
    hidden: true,
  },
  {
    path: `${getSimsDetailsPath}`,
    exact: true,
    name: 'SIM Details',
    component: SIMDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getDataPlanDetailsPath}`,
    exact: true,
    name: 'Data Plan Details',
    component: DataPlanDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    hidden: true,
  },
  {
    path: `${getDataPlanTypeDetailsPath}`,
    exact: true,
    name: 'Data Plan Type Details',
    component: DataPlanTypeDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
    ],
    hidden: true,
  },
  // LEGAL
  {
    path: `${getLegalPage}`,
    exact: true,
    name: 'Legal',
    component: Legal,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
    ],
    hidden: true,
  },
  {
    path: `${getServiceLevelAgreementPage}`,
    exact: true,
    name: 'SLA',
    component: SLA,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
    ],
    hidden: true,
  },
  {
    path: `${getPricingPage}`,
    exact: true,
    name: 'Pricing',
    component: Pricing,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
    ],
    hidden: true,
  },
  // SECURE ALARM
  {
    path: `${getSecureALARMManagerBucketDetails}`,
    exact: true,
    name: 'Bucket Details',
    component: BucketDetails,
    allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
    hidden: true,
  },
  {
    path: `${getSecureALARMManagerDidsBulk}`,
    exact: true,
    name: 'Dids bulk',
    component: SecureAlarmDidsBulk,
    allowedPermissionRoles: [PERMISSIONROLECONSTANTS.superadmin],
    hidden: true,
  },
  // OTHER
  {
    path: `${getCountriesDetailsPath}`,
    exact: true,
    name: 'Country Details',
    component: CountryDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
    ],
    hidden: true,
  },
  {
    path: `${getIssueTrackerDetailsPath}`,
    exact: true,
    name: 'Issue Tracker Details',
    component: IssueTrackerDetails,
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
    ],
    hidden: true,
  },
  {
    path: `${getServiceCallTechnicianDetailsPath}`,
    exact: true,
    name: 'Service Technician Details',
    component: ServiceCallTechnicianDetails,
    allowedPermissionRoles: serviceCallTechnicianPermissions.READ,
    hidden: true,
  },
  {
    path: '/',
    exact: true,
    pathTo: '/dashboard/noc',
    name: 'NOC',
    allowedPermissionRoles: [
      PERMISSIONROLECONSTANTS.superadmin,
      ...getAllPermissionRolesForUserLevel('telcloud'),
      ...getAllPermissionRolesForUserLevel('reseller'),
      ...getAllPermissionRolesForUserLevel('customer'),
    ],
    redirect: true,
  },
];
export default ThemeRoutes;
