import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import {
  CreateServiceCallTechnicianDto,
  ServiceCallTechnicianType,
  UpdateServiceCallTechnicianDto,
} from '../../types/service-call-technician/service-call-technician.type';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';

export const postServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { data: CreateServiceCallTechnicianDto; img?: File },
  { state: RootState }
>('serviceCall/postServiceCallTechnician', async ({ data, img }, thunkAPI) => {
  try {
    const formData = new FormData();
    if (img) formData.append('img', img);
    formData.append('body', JSON.stringify(data));

    const response = await axios.post<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating technicians!');
  }
});

export const getServiceCallTechniciansList = createAsyncThunk<
  PaginationType<ServiceCallTechnicianType>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallTechniciansList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<ServiceCallTechnicianType>>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching technicians!');
  }
});

export const getServiceCallTechnicianDetails = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallTechnicianDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching technician!');
  }
});

export const patchServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number; data: UpdateServiceCallTechnicianDto; img?: File },
  { state: RootState }
>('serviceCall/patchServiceCallTechnician', async ({ data, id, img }, thunkAPI) => {
  try {
    const formData = new FormData();
    if (img) formData.append('img', img);
    formData.append('body', JSON.stringify(data));

    const response = await axios.patch<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating technicians!');
  }
});

export const deleteServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallTechnician', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(
        getServiceCallTechniciansList(thunkAPI.getState().serviceCallReducer.serviceCallTechniciansRouteParams)
      );
    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting technicians!');
  }
});
