import { PaginationType } from '../../types/utils/pagination.type';
import { ServiceCallTechnicianType } from '../../types/service-call-technician/service-call-technician.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteServiceCallTechnician,
  getServiceCallTechnicianDetails,
  getServiceCallTechniciansList,
  patchServiceCallTechnician,
  postServiceCallTechnician,
} from './serviceCall.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface ServiceCallState {
  serviceCallTechniciansList: null | PaginationType<ServiceCallTechnicianType>;
  serviceCallTechniciansListFetchStatus: FETCH_STATUS;
  serviceCallTechniciansRouteParams: RouteParamsType;
  serviceCallTechnicianDetails: null | ServiceCallTechnicianType;
  serviceCallTechnicianDetailsFetchStatus: FETCH_STATUS;
  serviceCallTechnicianDetailsPostStatus: FETCH_STATUS;
  serviceCallTechnicianDetailsPatchStatus: FETCH_STATUS;
  serviceCallTechnicianDetailsDeleteStatus: FETCH_STATUS;
}

export const initialServiceCallTechniciansRouteParams = {
  page: 1,
  limit: 10,
  order: 'technician.id',
  q: '',
  provider_id: '',
};

const INIT_STATE: ServiceCallState = {
  serviceCallTechniciansList: null,
  serviceCallTechniciansListFetchStatus: FETCH_STATUS.NULL,
  serviceCallTechniciansRouteParams: initialServiceCallTechniciansRouteParams,
  serviceCallTechnicianDetails: null,
  serviceCallTechnicianDetailsFetchStatus: FETCH_STATUS.NULL,
  serviceCallTechnicianDetailsDeleteStatus: FETCH_STATUS.NULL,
  serviceCallTechnicianDetailsPatchStatus: FETCH_STATUS.NULL,
  serviceCallTechnicianDetailsPostStatus: FETCH_STATUS.NULL,
};

export const serviceCallSlice = createSlice({
  name: 'serviceCall',
  initialState: INIT_STATE,
  reducers: {
    setServiceCallTechniciansList: (state, action) => {
      state.serviceCallTechniciansList = action.payload;
    },
    setServiceCallTechniciansListFetchStatus: (state, action) => {
      state.serviceCallTechniciansListFetchStatus = action.payload;
    },
    setServiceCallTechnicianDetails: (state, action) => {
      state.serviceCallTechnicianDetails = action.payload;
    },
    setServiceCallTechnicianDetailsFetchStatus: (state, action) => {
      state.serviceCallTechnicianDetailsFetchStatus = action.payload;
    },
    setServiceCallTechnicianDetailsPostStatus: (state, action) => {
      state.serviceCallTechnicianDetailsPostStatus = action.payload;
    },
    setServiceCallTechnicianDetailsPatchStatus: (state, action) => {
      state.serviceCallTechnicianDetailsPatchStatus = action.payload;
    },
    setServiceCallTechnicianDetailsDeleteStatus: (state, action) => {
      state.serviceCallTechnicianDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'service-call-technicians') {
        state.serviceCallTechniciansRouteParams = { ...state.serviceCallTechniciansRouteParams, ...payload.data };
      }
    });

    // CREATE SERVICE CALL TECHNICIAN DETAILS
    builder.addCase(postServiceCallTechnician.pending, state => {
      state.serviceCallTechnicianDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postServiceCallTechnician.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postServiceCallTechnician.fulfilled, state => {
      state.serviceCallTechnicianDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Technician created successfully');
      });
    });

    // GET SERVICE CALL TECHNICIANS LIST
    builder.addCase(getServiceCallTechniciansList.pending, state => {
      state.serviceCallTechniciansListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallTechniciansList.rejected, (state, action) => {
      state.serviceCallTechniciansListFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallTechniciansList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallTechniciansList.fulfilled, (state, action) => {
      state.serviceCallTechniciansListFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallTechniciansList = action.payload;
    });

    // GET SERVICE CALL TECHNICIAN DETAILS
    builder.addCase(getServiceCallTechnicianDetails.pending, state => {
      state.serviceCallTechnicianDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallTechnicianDetails.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallTechnicianDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallTechnicianDetails.fulfilled, (state, action) => {
      state.serviceCallTechnicianDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallTechnicianDetails = action.payload;
    });

    // UPDATE BATTERIES DETAILS
    builder.addCase(patchServiceCallTechnician.pending, state => {
      state.serviceCallTechnicianDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchServiceCallTechnician.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchServiceCallTechnician.fulfilled, state => {
      state.serviceCallTechnicianDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Technician updated successfully');
      });
    });

    // UPDATE BATTERIES DETAILS
    builder.addCase(deleteServiceCallTechnician.pending, state => {
      state.serviceCallTechnicianDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteServiceCallTechnician.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteServiceCallTechnician.fulfilled, state => {
      state.serviceCallTechnicianDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Technician deleted successfully');
      });
    });
  },
});

export const {
  setServiceCallTechniciansListFetchStatus,
  setServiceCallTechniciansList,
  setServiceCallTechnicianDetails,
  setServiceCallTechnicianDetailsFetchStatus,
  setServiceCallTechnicianDetailsPostStatus,
  setServiceCallTechnicianDetailsPatchStatus,
  setServiceCallTechnicianDetailsDeleteStatus,
} = serviceCallSlice.actions;

export default serviceCallSlice.reducer;
